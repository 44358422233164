import { ArrowBack, RightMark } from "assets/icons";
import { useResponsive } from "hooks/useResponsive";

function ChannelSuccess() {
  const { isMobile } = useResponsive();

  return (
    <main className="w-full h-screen relative bg-maincolor flex flex-col items-center justify-center">
      {/* top section */}
      {!isMobile && (
        <a
          href="/"
          className="w-[50px] absolute left-20 top-10 xl:w-[70px] xl:pt-5"
        >
          <img src={ArrowBack} alt="" />
        </a>
      )}

      {/* bottom section */}
      {!isMobile ? (
        <div className="w-[380px] lg:w-[712px] mt-20 p-[20px] bg-[#4d276b] rounded-[40px] lg:rounded-[115px] flex flex-col items-center justify-center gap-[20px] text-white">
          <div className="mt-[-50px] w-[100px] lg:w-[200px]">
            <img src={RightMark} alt="" />
          </div>
          <p className="font-title text-center lg:text-[45px] lg:px-[100px]">
            Successfully Booked
          </p>
          <p className="font-subTitle text-center lg:text-[30px]">
            You will receive a confirmation email shortly
          </p>
        </div>
      ) : (
        <div className="w-[90%] h-[85%] flex flex-col items-center justify-center bg-[#4d276b] rounded-[40px] gap-y-10 text-white">
          <img className="w-[60%] h-[30%]" src={RightMark} alt="" />
          <p className="font-title text-center text-2xl">Successfully Booked</p>
          <p className="font-subTitle text-center text-xl">
            You will receive a confirmation email shortly
          </p>
        </div>
      )}
    </main>
  );
}

export default ChannelSuccess;
