import {
  BECOME_MERCHANT_PATH,
  CHANNEL_SUCCESS_PATH,
  HOME_PATH,
  MERCHANT_SUCCESS_PATH,
  REQUEST_DEMO_PATH,
} from "Routes/paths";
import Home from "screens/Home/Home";
import RequestDemo from "screens/RequestDemo/RequestDemo";
import BecomeMerchant from "screens/BecomeMerchant/BecomeMerchant";
import ChannelSuccess from "screens/ChannelSuccess/ChannelSuccess";
import MerchantSuccess from "screens/MerchantSuccess/MerchantSuccess";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  {
    path: HOME_PATH,
    element: <Home />,
  },
  {
    path: REQUEST_DEMO_PATH,
    element: <RequestDemo />,
  },
  {
    path: BECOME_MERCHANT_PATH,
    element: <BecomeMerchant />,
  },
  {
    path: CHANNEL_SUCCESS_PATH,
    element: <ChannelSuccess />
  },
  {
    path: MERCHANT_SUCCESS_PATH,
    element: <MerchantSuccess />
  }
];
