import { Facebook, Instagram, Linkedin, Mail, StarFlipped } from "assets/icons";

const Footer = () => {
  return (
    <footer
      id="contactus"
      className="relative w-full h-[630px] mt-[100px] bg-maincolor bg-cover flex flex-col md:flex-row items-center md:items-start justify-start md:justify-center gap-x-[10rem] pt-[10rem]"
    >
      <p className="font-title text-white text-[50px] md:text-[80px]">
        Contact Us
      </p>
      <div className="flex flex-col items-start gap-y-6">
        <a
          href="mailto:Info@st-lr.com"
          className="flex flex-row items-center gap-x-5"
        >
          <img className="w-[60px] h-[60px]" src={Mail} alt="mail" />
          <p className="text-white text-[35px] font-body">Info@st-lr.com</p>
        </a>
        <a
          href="https://www.instagram.com/stlr.loyalty"
          className="flex flex-row items-center gap-x-5"
        >
          <img className="w-[60px] h-[60px]" src={Instagram} alt="instagram" />
          <p className="text-white text-[35px] font-body">Stlrloyalty</p>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61558056496801"
          className="flex flex-row items-center gap-x-5"
        >
          <img className="w-[60px] h-[60px]" src={Facebook} alt="facebook" />
          <p className="text-white text-[35px] font-body">STLR</p>
        </a>
        <a
          href="https://www.linkedin.com/company/stlrloyalty/"
          className="flex flex-row items-center gap-x-5"
        >
          <img className="w-[60px] h-[60px]" src={Linkedin} alt="stlr" />
          <p className="text-white text-[35px] font-body">STLR</p>
        </a>
      </div>
      <img
        className="hidden md:block md:absolute top-[-25rem] left-[-10rem]"
        src={StarFlipped}
        alt="star"
      />
    </footer>
  );
};

export default Footer;
