import { Link, useNavigate } from "react-router-dom";
import { StarShadowPng } from "assets/images";
import { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { requestDemoAction } from "redux/actions/requestDemoAction";

function RequestDemo() {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workNumber, setWorkNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const { demoLoading } = useSelector(
    (state: RootState) => state.requestDemoReducer
  );

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 4000);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (firstName.trim() === "") {
      notification.error({
        message: "Please Enter Your First Name",
      });
      return;
    }
    if (lastName.trim() === "") {
      notification.error({
        message: "Please Enter Your Last Name",
      });
      return;
    }
    if (workNumber.trim() === "") {
      notification.error({
        message: "Please Enter Your Work Number",
      });
      return;
    }
    if (email.trim() === "") {
      notification.error({
        message: "Please Enter Your Work Email",
      });
      return;
    }
    if (description.trim() === "") {
      notification.error({
        message: "Please Enter Your Business Description",
      });
      return;
    }

    await dispatch(
      requestDemoAction(
        {
          first_name: firstName,
          last_name: lastName,
          work_number: workNumber,
          email: email,
          description: description,
        },
        () => {
          navigate("/channel-success");
        }
      )
    );
  };

  return showLoading ? (
    <div className="w-full h-full bg-[#2E104D]">
      <iframe
        width="100%"
        height="100%"
        src="https://xd.adobe.com/embed/173b9cf1-d72a-4479-99b8-36f19ce0a37f-4268/"
      ></iframe>
    </div>
  ) : (
    <section className="relative w-full lg:w-[80%] h-screen bg-maincolor">
      {/* arrow back */}
      <Link
        to={"/"}
        className="w-[25px] md:w-[55px] absolute top-[5%] md:top-[10%] left-[10%]"
      ></Link>
      {/* main section */}
      <div className="md:ml-[100px] pt-8 pb-5 md:pt-[60px] 2xl:ml-[300px] w-full h-full flex flex-col lg:flex-row items-center justify-end gap-[10px] md:gap-[30px]">
        {/* text section */}
        <div className="relative w-[300px] 2xl:w-[450px] bg-white rounded-[75px] px-[30px] pb-[50px] pt-[50px] md:pt-[70px] max-h-[90%]">
          <p className="font-body font-[800] text-[15px] 2xl:text-[28px] md:leading-6">
            Our tailored loyalty solutions cater to diverse industries, offering
            customized programs, rewards management, and advanced analytics. We
            specialize in personalized strategies for optimal engagement and
            satisfaction. Understanding each business’s unique needs, we align
            our services to drive meaningful interactions and long-term loyalty.
            With our approach, businesses unlock loyalty’s full potential for
            sustainable growth in today’s competitive market.
          </p>
          <div className="hidden md:block absolute top-[-20%] left-[-30%] 2xl:left-[-30%] w-[200px] 2xl:w-[250px]">
            <img src={StarShadowPng} alt="" />
          </div>
        </div>

        {/* request a demo form */}
        <div className="w-[300px] 2xl:w-[535px] h-[95%] overflow-y-scroll bg-white rounded-[75px] px-[20px] py-[50px] flex flex-col gap-[40px]">
          <p className="text-maincolor text-[20px] 2xl:text-[40px] font-subTitle">
            Request A Demo
          </p>
          {/* form */}
          <div className="w-full">
            <form
              className=" 2xl:px-[30px] flex flex-col gap-[35px]"
              onSubmit={handleSubmit}
            >
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="text"
                placeholder="First Name*"
                onChange={(e: any) => setFirstName(e.target.value)}
              />
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="text"
                placeholder="Last Name*"
                onChange={(e: any) => setLastName(e.target.value)}
              />
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="text"
                placeholder="Work Number*"
                onChange={(e: any) => setWorkNumber(e.target.value)}
              />
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="email"
                placeholder="Work Email*"
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <textarea
                className="w-full pt-[50px] border rounded-[80px] h-[314px] pl-[40px] focus:outline-none text-[20px]"
                placeholder="Description of the Business*"
                onChange={(e: any) => setDescription(e.target.value)}
              />
              <button
                type="submit"
                className="w-full h-[60px] 2xl:h-[114px] bg-maincolor text-white text-[20px] 2xl:text-[40px] font-subTitle rounded-[80px]"
              >
                {demoLoading ? <Loader /> : "Book"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RequestDemo;
