import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  ArrowDark,
  ArrowWhite,
  CloseIcon,
  Cover,
  Engage,
  HamburgerMenu,
  Logo,
  LogoWhite,
  Message,
  Personalize,
  Retain,
  Star,
  StarTrans,
} from "assets/icons";
import Footer from "components/Footer/Footer";

const Home = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 4000);
  }, []);

  return showLoading ? (
    <div className="w-full h-full bg-[#2E104D]">
      <iframe
        width="100%"
        height="100%"
        src="https://xd.adobe.com/embed/173b9cf1-d72a-4479-99b8-36f19ce0a37f-4268/"
      ></iframe>
    </div>
  ) : (
    <>
      {/* the header */}
      <header className="w-full h-[120px] lg:h-[180px] px-[40px] lg:px-[140px] flex items-center gap-x-3 justify-between bg-white">
        {/* web nav bar */}
        <img
          onClick={() => setShow(true)}
          className="hover:cursor-pointer"
          src={HamburgerMenu}
          alt="hamburger"
        />

        {/* mobile nav bar */}
        <div
          onClick={() => setShow(!show)}
          className={
            show
              ? "overflow-y-hidden ease-out duration-300 fixed text-white left-0 top-0 w-full h-screen bg-maincolor/95 px-4 py-7 flex flex-col z-50"
              : "absolute top-0 w-full h-screen left-[-100%] ease-in duration-300"
          }
        >
          <img
            className="relative top-0 left-10 w-[3rem] h-[3rem] hover:cursor-pointer"
            alt="close-icon"
            src={CloseIcon}
            onClick={() => setShow(false)}
          />
          <ul className="h-full w-full text-center pt-[200px] font-subTitle text-[30px]">
            <li className=" py-8">
              <a
                href="#aboutus"
                className="font-subTitle hover:opacity-70 hover:!text-white"
              >
                About Us
              </a>
            </li>
            <li className=" py-8">
              <a
                href="#whystlr"
                className="font-subTitle hover:opacity-70 hover:!text-white"
              >
                Why STLR
              </a>
            </li>
            <li className=" py-8">
              <a
                href="#contactus"
                className="font-subTitle hover:opacity-70 hover:!text-white"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        {/* logo */}
        <div className="w-[130px] lg:w-[260px] lg:h-[75px]">
          <img className="object-contain" src={Logo} alt="" />
        </div>
      </header>

      {/* main section */}
      <main className="w-full px-[40px] lg:px-[140px] mt-[100px] bg-white">
        {/* first section */}
        <section className="w-full  flex flex-col xl:flex-row">
          <div className="xl:w-[50%] flex flex-col gap-[40px] text-maincolor">
            <p className="font-subTitle text-center md:text-left text-[30px] lg:text-[45px] font-[500]">
              Navigating the constellations <br />
              of{" "}
              <span
                id="shadow"
                className="font-title lg:text-[65px] xl:text-[65px]"
                style={{
                  textShadow: "20px 0px 6px #00000029",
                }}
              >
                LOYALTY
              </span>
            </p>
            <p className="text-center md:text-left font-body text-[30px] font-[800]">
              Your partner in crafting extraordinary loyalty experiences and
              building a vast merchant network and partnerships. Specialized in
              offering bespoke loyalty solutions, powered by cutting edge
              technology and insightful data analytics.
            </p>
            <div className="flex items-center justify-center lg:justify-start">
              <Link
                to={"/demo"}
                className="w-[300px] md:w-[500px] 1xl:w-[675px] h-[80px] md:h-[90px] xl:h-[140px] rounded-[73px] bg-maincolor text-white text-[30px] flex items-center justify-center gap-[20px] hover:cursor-pointer transition-all hover:opacity-70 duration-300 hover:text-white"
              >
                <p className="font-subTitle text-[18px] lg:text-[22px] 1xl:text-[30px]">
                  Request a Demo
                </p>
                <div className="w-[45px] lg:w-[80px]">
                  <img src={ArrowWhite} alt="" />
                </div>
              </Link>
            </div>
          </div>

          <div className="relative lg:w-[50%]">
            <img
              className="hidden xl:block xl:w-[600px] 2xl:w-[700px] absolute bottom-[-300px] left-[-10px]"
              src={Star}
              alt=""
            />
            <img
              className="hidden xl:block  xl:w-[400px] 2xl:w-[500px] absolute bottom-[-280px] left-[-60px]"
              src={Star}
              alt=""
            />
          </div>
        </section>

        {/* second section */}
        <section className="mt-[200px] md:mt-[300px] flex flex-col xl:flex-row justify-around gap-[120px] md:gap-[160px]">
          {/* first start */}
          <div className="flex flex-col items-center gap-[30px]">
            <div className="w-[220px] lg:w-[282px]">
              <img src={Engage} alt="" />
            </div>
            <p className="font-subTitle text-[30px]">Engage</p>
          </div>
          {/* second start */}
          <div className="flex flex-col items-center gap-[30px]">
            <div className="w-[220px] lg:w-[282px]">
              <img src={Personalize} alt="" />
            </div>
            <p className="font-subTitle text-[30px]">Personalize</p>
          </div>
          {/* third start */}
          <div className="flex flex-col items-center gap-[30px]">
            <div className="w-[220px] lg:w-[282px]">
              <img src={Retain} alt="" />
            </div>
            <p className="font-subTitle text-[30px]">Retain</p>
          </div>
        </section>

        {/* About Us section */}
        <section
          id="aboutus"
          className="mt-[100px] w-full flex flex-col xl:flex-row justify-between"
        >
          {/* side */}
          <div className="relative lg:w-[50%] ">
            <img
              className="hidden xl:block xl:w-[700px] absolute left-[-50px]"
              src={Message}
              alt=""
            />
            <img
              className="hidden xl:block xl:w-[500px] absolute bottom-[30px] left-[-230px]"
              src={StarTrans}
              alt=""
            />
          </div>
          {/* side */}
          <div className="xl:w-[50%] flex flex-col items-center gap-[40px] text-maincolor">
            <p className="font-title text-[38px] lg:text-[58px] 2xl:text-[100px] whitespace-nowrap">
              About Us
            </p>
            <p className="font-body text-center lg:text-left text-[30px] font-[800]">
              Our suite of loyalty solutions includes customized loyalty
              programs, rewards management, and customer analytics. Designed to
              cater to a variety of industries, our services are tailor-made to
              meet the unique needs of each business, ensuring maximum
              engagement and customer satisfaction.” “STLR is a dynamic merchant
              aggregator, offering a vibrant ecosystem for businesses to boost
              visibility and connectivity. By joining our network, you gain
              access to shared insights, cross-promotional opportunities,and
              increased customer traffic, enhancing both acquisition and
              retention. We highlight your brand’s unique qualities, fostering
              sustainable relationships and driving growth in a community
              dedicated to revolutionizing customer loyalty.
            </p>
          </div>
        </section>

        {/* why stlr section*/}
        <section
          id="whystlr"
          className="hidden w-full mt-[120px] lg:flex flex-col items-center gap-[50px]"
        >
          {/* top */}
          <div className="relative w-[80%] h-[1450px] bg-why bg-cover rounded-[80px] rounded-bl-[115px] shadow-[80px_55px_80px_0px_rgba(0,0,0,0.3)] text-white flex flex-col items-center gap-y-3 py-[100px] pt-[300px] px-[80px] 2xl:px-[180px]">
            <div className="w-full flex flex-row mt-8">
              <p className=" w-full ml-28 font-title text-right px-[40px] text-[50px] 2xl:text-[85px]">
                Why
              </p>
              <img src={LogoWhite} alt="logo-white" />
            </div>
            <p className="font-body text-[28px] text-center inline leading-9 font-[500]">
              <p className="text-white text-[70px] inline">.</p> Customized
              Solutions: We believe in a bespoke approach. Your business is
              unique, and your loyalty program should be too. Our tailored
              solutions ensure that your loyalty program aligns perfectly with
              your brand identity and business goals.
            </p>
            <p className="font-body text-[28px] text-center inline leading-9 font-[500]">
              <p className="text-white text-[70px] inline">.</p> Data-Driven
              Insights: Leveraging cutting-edge technology, coupled with
              providing insightful data analytics to track, measure, and
              optimize the performance of your loyalty programs. Thus, better
              decision-making and improved customer engagement for you.
            </p>
            <p className="font-body text-[28px] text-center inline leading-9 font-[500]">
              <p className="text-white text-[70px] inline">.</p> Data-Driven
              Scalable and Flexible: As your business evolves, so do our
              solutions. We offer scalable programs that adapt to your changing
              needs, ensuring long-term stability and sustainability.
            </p>
            <Link
              to={"/demo"}
              className="w-[340px] mt-14 md:w-[640px] 1xl:w-[675px] h-[80px] md:h-[90px] xl:h-[140px] xl:min-h-[140px] rounded-[73px] bg-white text-maincolor text-[30px] flex items-center justify-center gap-[20px] transition-all hover:cursor-pointer hover:bg-white hover:opacity-70 duration-300 hover:text-maincolor"
            >
              <p className="font-subTitle text-[18px] lg:text-[22px] xl:text-[30px]">
                Request a Demo
              </p>
              <div className="w-[45px] lg:w-[80px]">
                <img src={ArrowDark} alt="" />
              </div>
            </Link>

            {/* start-shadow */}
            <div className="absolute z-[-1] bottom-[-30%] right-[-31%] w-[500px] xl:w-[600px] 2xl:w-[700px]">
              {/* <img src={StarShadowPng} alt="" /> */}
            </div>
          </div>
        </section>

        {/* Become a merchant section */}
        <div className="w-full flex items-center justify-center my-[100px]">
          <Link
            to={"/become-merchant"}
            className="w-[340px] hover:opacity-70 hover:!text-white md:w-[640px] 1xl:w-[675px] h-[80px] md:h-[90px] xl:h-[140px] rounded-[73px] bg-sec_color text-white text-[30px] flex items-center justify-center gap-[20px] hover:cursor-pointer hover:bg-sec_color/85 duration-300"
          >
            <p className="font-subTitle text-[15px] lg:text-[22px] 1xl:text-[30px]">
              Become a merchant
            </p>
            <div className="w-[40px] lg:w-[80px]">
              <img src={ArrowWhite} alt="" />
            </div>
          </Link>
        </div>
      </main>

      {/* footer */}
      <Footer />
    </>
  );
};

export default Home;
