import { combineReducers } from "redux";
import { authReducer } from "redux/reducers/authReducer";
import { requestDemoReducer } from "redux/reducers/requestDemoReducer";

export const appReducer = combineReducers({
  authReducer,
  requestDemoReducer
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
