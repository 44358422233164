import
{
    REQUEST_DEMO_LOADING,
    REQUEST_DEMO_SUCCESS,
    REQUEST_DEMO_FAILURE
}
from "redux/types";

const initialState = {
    demoLoading: false,
    success: null,
    errors: null
}

export const requestDemoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REQUEST_DEMO_LOADING :
            return {...state, demoLoading: action.payload};
        case REQUEST_DEMO_SUCCESS :
            return {...state, success:action.payload.success};
        case REQUEST_DEMO_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}