/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";


const apiURL = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: apiURL,
});


const checkAuth = (notificationParam: any) => {
  // store.dispatch({ type: LOG_OUT });
  if (window.location.pathname === "/login") {
    notificationParam.message = "Authentication Fail";
    notificationParam.description = "Please check user name or password";
  } else if (window.location.pathname.includes("/posts/create")) {
    notificationParam.message = "Cannot create post without login";
    notificationParam.description = "Please login to your account";
  }
  else {
    notificationParam.message = "Something Went Wrong";
  }

};

// errors Handling
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const notificationParam = {
      message: "",
      description: "",
      style: {
        zIndex: 99999999999999,
      },
    };

    switch (error.response?.status) {
      case 401:
        checkAuth(notificationParam);
        break;
      case 400:
        notificationParam.message = "Bad Request";
        notificationParam.description = "Check that data is being sent";
        break;
      case 402:
        notificationParam.message = "لا يوجد لك كمية كافية في الحساب";
        notificationParam.description = "يمكنك شراء المزيد من المتجر";
        break;
      case 404:
        notificationParam.message = "Not Found";
        break;
      case 422:
        notificationParam.message = "Authentication Failed";
        notificationParam.description =
          "Wrong email/password, check your credentials";
        break;
      case 500:
        notificationParam.message = "Something went wrong";
        // window.location.href = "/404";
        break;
      case 508:
        notificationParam.message = "Time Out";
        notificationParam.description = "The server terminated an operation";
        break;
      default:
        break;
    }
  }
);
export default instance;
