//Auth Types
export const AUTH_LOADING = "AUTH_LOADING";
export const LOG_IN = "LOG_IN";
export const SET_USER = "SET_USER";
export const LOG_IN_FAILED = "LOG_IN_FAILED";
export const TRY_AGAIN = "TRY_AGAIN";
export const LOG_OUT = "LOG_OUT";
export const RESET_AUTH = "RESET_AUTH";

export const REQUEST_DEMO_LOADING = "REQUEST_DEMO_LOADING";
export const REQUEST_DEMO_SUCCESS = "REQUEST_DEMO_SUCCESS";
export const REQUEST_DEMO_FAILURE = "REQUEST_DEMO_FAILURE";