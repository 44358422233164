import { Link, useNavigate } from "react-router-dom";
import { StarShadowPng } from "assets/images";
import { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { notification } from "antd";
import { requestDemoAction } from "redux/actions/requestDemoAction";
import { RootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function BecomeMerchant() {
  const [showLoading, setShowLoading] = useState(true);

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workNumber, setWorkNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const { demoLoading } = useSelector(
    (state: RootState) => state.requestDemoReducer
  );

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 4000);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (firstName.trim() === "") {
      notification.error({
        message: "Please Enter Your First Name",
      });
      return;
    }
    if (lastName.trim() === "") {
      notification.error({
        message: "Please Enter Your Last Name",
      });
      return;
    }
    if (workNumber.trim() === "") {
      notification.error({
        message: "Please Enter Your Work Number",
      });
      return;
    }
    if (email.trim() === "") {
      notification.error({
        message: "Please Enter Your Work Email",
      });
      return;
    }
    if (description.trim() === "") {
      notification.error({
        message: "Please Enter Your Business Description",
      });
      return;
    }

    await dispatch(
      requestDemoAction(
        {
          first_name: firstName,
          last_name: lastName,
          work_number: workNumber,
          email: email,
          description: description,
        },
        () => {
          navigate("/merchant-success");
        }
      )
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 4000);
  }, []);

  return showLoading ? (
    <div className="w-full h-full bg-[#2E104D]">
      <iframe
        width="100%"
        height="100%"
        src="https://xd.adobe.com/embed/173b9cf1-d72a-4479-99b8-36f19ce0a37f-4268/"
      ></iframe>
    </div>
  ) : (
    <section className="relative w-full lg:w-[80%] h-screen bg-sec_color">
      {/* arrow back */}
      <Link
        to={"/"}
        className="w-[25px] md:w-[55px] absolute top-[5%] md:top-[10%] left-[10%]"
      >
        <img src="../images/arrow-back.png" alt="" />
      </Link>
      {/* main section */}
      <div className="md:ml-[100px] pt-8 pb-5 md:pt-[60px] 2xl:ml-[300px] w-full h-full flex flex-col lg:flex-row items-center justify-end gap-[30px]">
        {/* text section */}
        <div className="relative w-[300px] 2xl:w-[450px] bg-white rounded-[75px] pl-[40px] pr-[20px] pb-[50px] pt-[50px] md:pt-[70px] max-h-[90%]">
          <p className="font-body font-[800] text-[15px] 2xl:text-[25px] md:leading-6">
            STLR acts as a lively merchant aggregator, offering businesses a
            platform to enhance their visibility and connections. Joining our
            network grants you access to valuable insights, cross-promotion
            opportunities, and increased customer traffic, boosting both
            acquisition and retention efforts. We prioritize highlighting your
            brand’s uniqueness, fostering lasting connections, and driving
            growth within a community dedicated to redefining customer loyalty.
            Join us to amplify your presence and excel in today’s competitive
            market.
          </p>
          <div className="hidden md:block absolute top-[-20%] left-[-30%] 2xl:left-[-30%] w-[200px] 2xl:w-[250px]">
            <img src={StarShadowPng} alt="" />
          </div>
        </div>

        {/* Become a Merchant form */}
        <div className="w-[300px] 2xl:w-[535px] h-[95%] overflow-y-scroll bg-white rounded-[75px] px-[20px] py-[50px] flex flex-col gap-[40px]">
          <p className="text-sec_color text-[18px] 2xl:text-[33px] font-subTitle whitespace-nowrap">
            Become a Merchant
          </p>
          {/* form */}
          <div className="w-full">
            <form
              onSubmit={handleSubmit}
              className=" 2xl:px-[30px] flex flex-col gap-[35px]"
            >
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="text"
                placeholder="First Name*"
                onChange={(e: any) => setFirstName(e.target.value)}
              />
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="text"
                placeholder="Last Name*"
                onChange={(e: any) => setLastName(e.target.value)}
              />
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="text"
                placeholder="Work Number*"
                onChange={(e: any) => setWorkNumber(e.target.value)}
              />
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="email"
                placeholder="Work Email*"
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <input
                className="w-full border rounded-[80px] h-[60px] 2xl:h-[114px] pl-[40px] focus:outline-none text-[20px]"
                type="number"
                placeholder="Number of branches (if there are ones)*"
              />
              <textarea
                className="w-full pt-[50px] border rounded-[80px] h-[314px] pl-[40px] focus:outline-none text-[20px]"
                placeholder="Chains: (if there are ones)"
                onChange={(e: any) => setDescription(e.target.value)}
              />
              <button
                type="submit"
                className="w-full h-[60px] 2xl:h-[114px] bg-sec_color text-white text-[20px] 2xl:text-[40px] font-subTitle rounded-[80px]"
              >
                {demoLoading ? <Loader /> : "Book"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BecomeMerchant;
