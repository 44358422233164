import {
    AUTH_LOADING,
    LOG_IN,
    LOG_IN_FAILED,
    RESET_AUTH,
    
  } from 'redux/types';
  
  const initialState = {
    loading: false,
    error: null,
    dataAdmin: false,
  };
  
  export const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case AUTH_LOADING:
        return {...state, loading: action.payload};
      case LOG_IN:
        return {...state, dataAdmin: action.payload};
      case LOG_IN_FAILED:
        return {...state, error: action.payload};
      case RESET_AUTH:
        return initialState;
      default:
        return state;
    }
  };
  