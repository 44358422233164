import
{
    REQUEST_DEMO_LOADING,
    REQUEST_DEMO_SUCCESS,
    REQUEST_DEMO_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const requestDemoAction = (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: REQUEST_DEMO_LOADING, payload: true});
        const response = await createNewItemService('/api/stlr/mail', data);
        dispatch({type: REQUEST_DEMO_SUCCESS, payload: response.data});
        callback();
    } catch (err) {
        dispatch({type: REQUEST_DEMO_FAILURE, payload: err});
    }
    finally {   
        dispatch({type: REQUEST_DEMO_LOADING, payload: false});
    }
}